<template>
    <ion-page>
        <div class="toolbar">
            <span class="header-title">Izishushe</span>
        </div>
        <ion-content class="ion-padding">
            <div class="container">
                <div v-if="loading" class="result">Rindira...</div>
                <div v-else-if="!movies || !movies.length" class="result">No data available</div>
                <div v-else class="card-movie">
                    <div class="card" v-for="item in movies" :key="item.id">
                        <router-link class="card-link" :to="`/movie/${item.id}`">
                            <img loading="lazy" :src="item.poster" alt="Image" class="card-image" />
                            <div class="card-title">{{ cutText(item.title) }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
export default {
    data() {
        return {
            loading: false
        };
    },
    computed: {
        movies() {
            return this.$store.state.trending_movies;
        }
    },
    methods: {
        getMovies(url = null) {
            if (!url) {
                url = this.$store.state.baseUrl + '/trending/';
            }
            this.loading = true
            this.axios.get(url)
                .then(response => {
                    this.$store.state.trending_movies = response.data.results
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false
                });
        },
    },
    mounted() {
        this.getMovies();
    },
};
</script>

<style scoped></style>
