<template>
    <ion-page>
        <div class="toolbar">
            <span class="header-title">Ibigize ireresi</span>
        </div>
        <ion-content class="ion-padding">
            <div class="container">
                <div v-if="loading" class="result">Rindira...</div>
                <div v-else-if="data_error.detail" class="result">
                    <span>{{ data_error.detail }}</span>
                </div>
                <div v-else>
                    <div class="movie-detail-card" id="top">
                        <video ref="videoPlayer" id="player" class="video-js vjs-default-skin" :poster="movie.poster">
                        </video>
                        <div class="play">
                            <div class="button-option1">
                                <div v-for="(season, seasonIndex) in allSeasons" :key="seasonIndex"
                                    @click="showSeasonDetails(seasonIndex)" v-show="isSeasonSelected(seasonIndex)">
                                    <div class="btn-main" @click="playVideo(season?.trailer_src)">
                                        <ion-icon :icon="$allIcons.filmOutline"></ion-icon>
                                        <span>Imponjo S{{ season?.number }}</span>
                                    </div>
                                </div>
                                <div class="btn-main" v-if="movie?.seasons?.length === 0"
                                    @click="playVideo(movie?.trailer_src)">
                                    <ion-icon :icon="$allIcons.filmOutline"></ion-icon>
                                    <span>Imponjo</span>
                                </div>
                                <div class="option-button" @click="addToMyList(movie.id)">
                                    <ion-icon :icon="$allIcons.heartOutline"></ion-icon>
                                    <span>Izanje</span>
                                </div>
                            </div>
                            <div class="button-option2">
                                <div v-if="movie.full_video_src === null || movie.full_video_src === ''"
                                    @click="scrollTo('middle')">
                                    <div v-for="(season, seasonIndex) in allSeasons" :key="seasonIndex"
                                        @click="showSeasonDetails(seasonIndex)" v-show="isSeasonSelected(seasonIndex)">
                                        <div class="btn-main">
                                            <ion-icon :icon="$allIcons.play"></ion-icon>
                                            <span>S{{ season?.number }} Episodes</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="btn-main" @click="playVideo(movie.full_video_src)">
                                        <ion-icon :icon="$allIcons.play"></ion-icon>
                                        <span>Raba aha</span>
                                    </div>
                                </div>
                                <div class="option-button" @click="shareContent(movie.id)">
                                    <ion-icon :icon="$allIcons.shareSocialOutline"></ion-icon>
                                    <span>Sangiza</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="movie-infos">
                        <span class="card-title">{{ movie.title }}</span>
                        <div class="infos">
                            <span class="sub-gray">- Views • {{ moment(movie.created_at).format('MMMM DD, YYYY')
                                }}
                                <span id="open-modal" expand="block" class="card-title">...Ibindi</span>
                            </span>
                        </div>
                        <span class="card-title" v-if="movie?.seasons?.length !== 0">Seasons</span>
                        <div class="slider">
                            <div class="btn" v-for="(season, seasonIndex) in allSeasons" :key="seasonIndex"
                                :class="{ 'selected': isSeasonSelected(seasonIndex) }"
                                @click="showSeasonDetails(seasonIndex)">
                                <span>S{{ season.number }}</span>
                            </div>
                        </div>
                        <div class="buttons">
                            <div v-for="(season, seasonIndex) in allSeasons" :key="seasonIndex"
                                @click="showSeasonDetails(seasonIndex)" v-show="isSeasonSelected(seasonIndex)">
                                <div class="btn-main" @click="playVideo(season?.trailer_src)">
                                    <ion-icon :icon="$allIcons.filmOutline"></ion-icon>
                                    <span>Imponjo S{{ season?.number }}</span>
                                </div>
                            </div>
                            <div class="buttons" v-if="movie?.seasons?.length === 0">
                                <div v-if="hasUrl !== movie.trailer_src">
                                    <div class="btn-main" @click="playVideo(movie?.trailer_src)">
                                        <ion-icon :icon="$allIcons.filmOutline"></ion-icon>
                                        <span>Imponjo</span>
                                    </div>
                                </div>
                                <div v-if="hasUrl !== movie.full_video_src && movie.full_video_src !== null && movie.full_video_src !== ''"
                                    class="btn-main" @click="playVideo(movie.full_video_src)">
                                    <ion-icon :icon="$allIcons.play"></ion-icon>
                                    <span>Raba aha</span>
                                </div>
                            </div>
                        </div>
                        <div class="seasons" v-for="(season, seasonIndex) in allSeasons" :key="seasonIndex"
                            v-show="isSeasonSelected(seasonIndex)">
                            <span class="card-title">{{ season.title }}</span>
                            <span class="sub-gray">{{ moment(season.release_date).format('MMMM DD, YYYY') }}</span>
                            <div class="description">
                                <span>
                                    {{ fullText === season.description ? season.description :
                                        season.description?.slice(0,
                                            60) + (season.description?.length > 60 ?
                                                ' ...' : '') }}
                                    <span v-if="fullText !== season?.description && season?.description?.length > 60"
                                        @click="showMore(season.description)">Ibindi</span>
                                </span>
                            </div>
                            <span class="card-title" id="middle">Episodes ({{ season?.episodes?.length }})</span>
                            <div v-for="(episode, episodeIndex) in season?.episodes" :key="episodeIndex">
                                <div class="episode-item" @click="playVideo(episode?.video_src)">
                                    <div class="play-sign" v-if="episode_id === episode.id">
                                        <span class="play-sign-item level-1"></span>
                                        <span class="play-sign-item level-2"></span>
                                        <span class="play-sign-item level-3"></span>
                                    </div>
                                    <img loading="lazy" @click="scrollTo('top')" :src="season.poster"
                                        class="large-image">
                                    <div class="episode-infos">
                                        <span class="card-title">E{{ episode?.number }} • {{ episode?.title }}</span>
                                        <span class="sub-gray">{{ episode?.description }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ion-modal ref="modal" trigger="open-modal" :initial-breakpoint="0.6" :breakpoints="[0.6, 1]">
                        <ion-content class="ion-padding">
                            <div class="modal-scroll">
                                <span class="card-title">{{ movie.title }}</span>
                                <div class="stati">
                                    <div class="couple">
                                        <span class="card-title">-</span>
                                        <span class="sub-gray">Likes</span>
                                    </div>
                                    <div class="couple">
                                        <span class="card-title">-</span>
                                        <span class="sub-gray">Views</span>
                                    </div>
                                    <div class="couple">
                                        <span class="card-title">{{ moment(movie.created_at).format('MMMM DD')
                                            }}</span>
                                        <span class="sub-gray">{{ moment(movie.created_at).format('YYYY') }}</span>
                                    </div>
                                </div>
                                <div class="category">
                                    <span v-if="movie.is_series === true">Serie</span>
                                    <span v-else>Full movie</span>
                                    <div v-for="(item, index) in movie?.categories" :key="index">
                                        <span> • {{ item.name }}</span>
                                    </div>
                                </div>
                                <div class="description">
                                    <span>
                                        {{ fullText === movie.description ? movie.description :
                                            movie.description?.slice(0,
                                                60) + (movie.description?.length > 60 ?
                                                    ' ...' : '') }}
                                        <span v-if="fullText !== movie.description && movie.description?.length > 60"
                                            @click="showMore(movie.description)">Ibindi</span>
                                    </span>
                                </div>
                                <span class="card-title">Director</span>
                                <ion-list>
                                    <ion-item>
                                        <ion-label>
                                            <div class="infos">
                                                <img loading="lazy" :src="movie.director_photo" />
                                                <span class="card-title">{{ movie.director_name }}</span>
                                            </div>
                                            <div class="description">
                                                <span>
                                                    {{ fullText === movie.director_bio ? movie.director_bio :
                                                        movie.director_bio?.slice(0, 60) +
                                                        (movie.director_bio?.length > 60 ?
                                                            ' ...' : '') }}
                                                    <span
                                                        v-if="fullText !== movie.director_bio && movie.director_bio?.length > 60"
                                                        @click="showMore(movie.director_bio)">Ibindi</span>
                                                </span>
                                            </div>
                                        </ion-label>
                                    </ion-item>
                                </ion-list>
                                <span class="card-title">Actors</span>
                                <ion-list v-for="(item, index) in movie.actors" :key="index">
                                    <ion-item>
                                        <ion-label>
                                            <div class="infos">
                                                <img loading="lazy" :src="item.photo" />
                                                <span class="card-title">{{ item.name }}</span>
                                            </div>
                                            <div class="description">
                                                <span>
                                                    {{ fullText === item.bio ? item.bio : item.bio?.slice(0, 60) +
                                                        (item.bio?.length > 60 ?
                                                            ' ...' : '') }}
                                                    <span v-if="fullText !== item.bio && item.bio?.length > 60"
                                                        @click="showMore(item.bio)">Ibindi</span>
                                                </span>
                                            </div>
                                        </ion-label>
                                    </ion-item>
                                </ion-list>
                            </div>
                        </ion-content>
                    </ion-modal>
                    <OurRecommended />
                    <OurTrending />
                    <OurPayment v-show="modal_payment" @close="close" />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import moment from 'moment';
import OurRecommended from '@/components/OurRecommended.vue';
import OurTrending from '@/components/OurTrending.vue';
import OurPayment from '@/components/account/OurPayment.vue'


import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
    data() {
        return {
            loading: false,
            moment: moment,
            movie: '',
            season_id: null,
            episodes: [],
            episode_id: null,
            selectedSeasonIndex: 0,
            data_error: '',
            fullText: null,
            hasUrl: '',
            ourPlayTime: null,
            watchStartTime: null,
            modal_payment: false
        };
    },
    components: {
        OurRecommended,
        OurTrending,
        OurPayment
    },
    computed: {
        allSeasons() {
            return this.movie?.seasons || [];
        },
        allEpisodes() {
            return this.allSeasons.flatMap(season => season.episodes);
        },
        isSeasonSelected() {
            return (index) => this.selectedSeasonIndex === index;
        },
        formatReleaseDate() {
            return (date) => this.$moment(date).format('MMMM DD, YYYY');
        },
        logged_in() {
            return this.$store.getters.getLogged_in;
        },
    },
    watch: {
        '$store.state.isPaid': function (newValue) {
            if (newValue === true) {
                this.close();
            }
        }
    },
    methods: {
        close() {
            this.modal_payment = false
        },
        showMore(text) {
            this.fullText = text;
        },
        showSeasonDetails(index) {
            this.selectedSeasonIndex = index;
        },
        getMovieDetail() {
            this.loading = true
            this.axios.get(this.$store.state.baseUrl + `/movies/${this.$route.params.id}/`)
                .then(response => {
                    this.movie = response?.data
                    this.$store.state.movie_id = this.movie.id
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                    this.data_error = error.response?.data
                    this.loading = false
                });
        },
        playVideo(videoSrc) {
            this.checkLogged_in()
            const videoType = videoSrc.split('.').pop(); // Extract video type
            this.hasUrl = videoSrc;

            const videoOptions = {
                autoplay: true,
                controls: true,
                sources: [
                    {
                        src: videoSrc,
                        type: `video/${videoType}`,
                    },
                ],
            };

            if (this.videoPlayer) {
                this.videoPlayer.src(videoOptions.sources[0]);
            } else {
                this.videoPlayer = videojs('player', videoOptions);
                this.$el.querySelector('.video-js').classList.add('video-playing'); // hide btn
                ['play', 'pause', 'ended', 'dispose'].forEach((event) => {
                    this.videoPlayer.on(event, () => {
                        const currentSrc = this.videoPlayer.currentSrc();
                        const ourPlayTime = this.videoPlayer.currentTime();

                        const seasonIndex = this.allSeasons?.findIndex((season) => season.trailer_src === currentSrc);
                        this.season_id = seasonIndex !== -1 ? seasonIndex + 1 : null;

                        const episodeIndex = this.allEpisodes?.findIndex((episode) => episode.video_src === currentSrc);
                        this.episode_id = episodeIndex !== -1 ? episodeIndex + 1 : null;


                        if (!this.$store.state.isPaid && this.movie?.trailer_src !== currentSrc && (seasonIndex === -1 || seasonIndex === null)) {
                            // If not paid, not the trailer source, and season index is invalid, show the payment modal and pause the video
                            this.modal_payment = true; // Show payment modal
                            this.videoPlayer.pause(); // Pause the video
                            return; // Exit the event handler
                        }


                        let watchEndTime = null
                        let watchDuration = null

                        if (event === 'play') {
                            // Start tracking time when the video is played
                            this.watchStartTime = new Date();

                        } else if (event === 'pause' || event === 'ended' || event === 'dispose') {
                            // Calculate duration when the video is paused or ended
                            watchEndTime = new Date();
                            watchDuration += watchEndTime - this.watchStartTime; // Duration in milliseconds

                            if (this.movie?.trailer_src === currentSrc && ourPlayTime >= 29) {
                                this.fullMovieTrailerView();
                            } else if (this.movie?.full_video_src === currentSrc && ourPlayTime >= 29) {
                                const movie_id = this.movie?.id
                                const episode_id = null
                                this.fullMovieView();
                                this.watchtime(movie_id, episode_id, this.watchStartTime, watchEndTime, watchDuration, ourPlayTime)
                            } else if (this.allSeasons?.some((season) => season.trailer_src === currentSrc && ourPlayTime >= 29)) {
                                this.seasonTrailerView(this.season_id);

                            } else if (this.allEpisodes?.some((episode) => episode.video_src === currentSrc && ourPlayTime >= 2)) {
                                const movie_id = null
                                this.watchtime(movie_id, this.episode_id, this.watchStartTime, watchEndTime, watchDuration, ourPlayTime)
                                this.episodeView(this.episode_id);
                            }
                        }

                    });

                });
            }
        },
        watchtime(movie_id, episode_id, watchStartTime, watchEndTime, watchDuration, ourPlayTime) {
            const data = {
                movie: movie_id,
                episode: episode_id,
                start_time: watchStartTime,
                end_time: watchEndTime,
                duration: watchDuration, // Duration in milliseconds
                continue_watch: ourPlayTime
            };
            this.axios.post(this.$store.state.baseUrl + `/watchtime/`, data, this.getHeaders())
                .then(response => {
                    console.log('Watch time saved successfully:', response.data);
                })
                .catch(error => {
                    console.error('Error saving watch time:', error);
                });
        },
        fullMovieTrailerView() {
            const data = {
                movie: this.movie?.id,
                season: null,
            };
            this.axios.post(this.$store.state.baseUrl + `/view-trailer/`, data, this.getHeaders())
                .then(response => {
                    console.log('full movie trailer View:', response.data);
                })
                .catch(error => {
                    console.error('Error updating view :', error);
                });
        },
        fullMovieView() {
            const data = {
                movie: this.movie?.id,
                episode: null,
            };
            this.axios.post(this.$store.state.baseUrl + `/views/`, data, this.getHeaders())
                .then(response => {
                    console.log('full movie View:', response.data);
                })
                .catch(error => {
                    console.error('Error view :', error);
                });
        },
        seasonTrailerView(id) {
            const data = {
                movie: null,
                season: id,
            };
            this.axios.post(this.$store.state.baseUrl + `/view-trailer/`, data, this.getHeaders())
                .then(response => {
                    console.log('season trailer View:', response.data);
                })
                .catch(error => {
                    console.error('Error updating view :', error);
                });
        },
        episodeView(id) {
            const data = {
                movie: null,
                episode: id,
            };
            this.axios.post(this.$store.state.baseUrl + `/views/`, data, this.getHeaders())
                .then(response => {
                    console.log('episode View:', response.data);
                })
                .catch(error => {
                    console.error('Error episode View :', error);
                });
        },
        scrollTo(id) {
            const element = document.getElementById(id);
            element.scrollIntoView({ behavior: 'smooth' });
        }
    },
    mounted() {
        this.getMovieDetail()
    },
    beforeUnmount() {
        if (this.videoPlayer) {
            this.videoPlayer.dispose();
        }
    }
};
</script>

<style></style>
