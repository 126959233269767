<template>
  <div class="see-all">
    <span class="title">Zishasha</span>
    <span class="see-all-btn" @click="GoRecently" v-if="!$store.state.loading_recently">Zose</span>
  </div>
  <div v-if="$store.state.loading_recently" class="sub-gray">Rindira...</div>
  <div v-else-if="!movies || !movies.length" class="sub-gray">No data available</div>
  <div v-else class="slider">
    <div class="card" v-for="(item, index) in movies" :key="index">
      <router-link class="card-link" :to="`/movie/${item.id}`">
        <img loading="lazy" :src="item.poster" alt="item" />
        <span class="card-title">{{ cutText(item.title) }}</span>
      </router-link>
    </div>
  </div>

</template>

<script>

export default {

  data() {
    return {
    };
  },
  computed: {
    movies() {
      return this.$store.state.movies;
    }
  },
  methods: {
    GoRecently() {
      this.$router.push('/tabs/ireresi');
    },
    getMovies(url = null) {
      if (!url) {
        url = this.$store.state.baseUrl + '/movies/';
      }
      this.$store.state.loading_recently = true
      this.axios.get(url)
        .then(response => {
          this.$store.state.movies = response.data.results
          this.$store.state.loading_recently = false
        })
        .catch(error => {
          console.log(error);
          this.$store.state.loading_recently = false
        });
    },
  },
  mounted() {
    this.getMovies();
  },
};
</script>

<style></style>