<template>
    <ion-page>
        <div class="toolbar">
            <span class="header-title">Izanje</span>
        </div>
        <ion-content class="ion-padding">
            <div class="container">
                <div v-if="loading" class="result">Rindira...</div>
                <div v-else-if="!movies || !movies.length" class="result">No data available</div>
                <div v-else class="card-movie">
                    <div class="card" v-for="item in movies" :key="item.id">
                        <router-link class="card-link" :to="`/movie/${item.movie_id}`">
                            <img loading="lazy" :src="item.movie_poster" alt="Image" class="card-image" />
                            <div class="card-title">{{ cutText(item.movie_title) }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
export default {
    data() {
        return {
            loading: false
        };
    },
    computed: {
        movies() {
            return this.$store.state.myList;
        }
    },
    methods: {
        getMoviesInMyList(url = null) {
            if (!url) {
                url = this.$store.state.baseUrl + '/my-list/';
            }
            this.loading = true
            this.axios.get(url, this.getHeaders())
                .then(response => {
                    this.$store.state.myList = response.data.results
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false
                });
        },
    },
    mounted() {
        this.getMoviesInMyList();
    },
};
</script>

<style>
.card-movie {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

a {
    text-decoration: none;
    color: #fff;
    text-align: center;
}
</style>
