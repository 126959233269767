// import axiosMethods from "./axiosMethods"
import store from '../store';

export default {
    // mixins: [axiosMethods],
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')),
            // paymentToken: JSON.parse(localStorage.getItem('paymentToken'))
        }
    },
    methods: {
        getHeaders() {
            return {
                headers: {
                    'Authorization': 'Bearer ' + this.user.access
                }
            };
        },
        checkLogged_in() {
            if (this.user) {
                const accessToken = this.user.access
                if (accessToken) {
                    const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
                    const expirationTime = decodedToken.exp;
                    const currentTime = Math.floor(Date.now() / 1000);

                    if (expirationTime < currentTime) {
                        localStorage.removeItem('user');
                        store.dispatch('setLogged_in', false);
                    } else {
                        store.dispatch('setLogged_in', true);
                        this.checkSubscription()
                    }
                }
            } else {
                this.$router.push('/login');
            }
        },
        checkSubscription() {
            this.axios.get(this.$store.state.baseUrl + `/last-subscription/`, this.getHeaders())
                .then(response => {
                    if (response.data.length > 0) {
                        this.$store.state.isPaid = true;
                        console.log('wararishe kweri', this.$store.state.isPaid);
                    } else {
                        // this.$router.push({ name: 'payment', query: { 'event_id': this.event_id } });
                        this.$store.state.isPaid = false;
                        console.log('riha', this.$store.state.isPaid);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        shareContent(id) {
            if (navigator.share) {
                navigator.share({
                    url: `https://freshmovie.egeratech.com/movie/${id}` // The URL to share
                })
                    .then(() => {
                        console.log('Sharing successful');
                    })
                    .catch((error) => {
                        console.error('Error sharing:', error);
                    });
            } else {
                console.log('Web Share API not supported.');
            }
        },
        addToMyList(movie_id) {
            this.checkLogged_in()
            if (this.logged_in) {
                let data = {
                    is_in_MyList: true,
                    movie: movie_id
                }
                this.axios.post(this.$store.state.baseUrl + '/my-list/', data, this.getHeaders())
                    .then(() => {
                        this.$store.dispatch('triggerToast', 'Vyaheze yamaze kujayo !');
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('triggerToast', error.response?.data.detail || 'An error occurred');
                    });
            } else {
                this.$router.push('/login')
            }
        },
        cutText(text) {
            let maxLength = 25;
            if (text?.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        // getCategories(url = null) {
        //     if (!url) {
        //         url = this.$store.state.baseUrl + '/categories/';
        //     }
        //     this.axios.get(url, this.getHeaders())
        //         .then(response => {
        //             this.$store.state.categories = response.data.results
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        // getYourVerifiedChannels(url = null) {
        //     if (!url) {
        //         url = this.$store.state.baseUrl + '/your-verified-channels/';
        //     }
        //     this.axios.get(url, this.getHeaders())
        //         .then(response => {
        //             this.$store.state.your_verified_channels = response.data.results
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        demanderAide() {
            let text = "N'amahoro neza, ni gute noriha kugira ndabe Fresh movie ??."
            let url = `https://wa.me/25761029393?text=${text}`
            window.open(url, '_system');
        },
        confirmLogout() {
            if (confirm('Vyukuri ushaka kwugara?')) {
                localStorage.removeItem('user');
                this.$store.dispatch('setLogged_in', false);
                this.$store.state.user = '';
                this.$store.dispatch('triggerToast', 'Sawa bayi !');
                this.$router.push('/')
            }
        },
    },
    computed: {
        logged_in() {
            return this.$store.getters.getLogged_in;
        }
    },
    mounted() {
    },
}