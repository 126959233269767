<template>
  <ion-page>
    <div class="toolbar">
      <span class="header-title">Ibindanga</span>
    </div>
    <ion-content>
      <div class="container">
        <div class="account-card">
          <div class="profile">
            <ion-icon :icon="$allIcons.personOutline"></ion-icon>
            <div class="info">
              <span>{{ this.$store.state.user.username }}</span>
              <span> {{ this.$store.state.user.email }} </span>
            </div>
          </div>
        </div>
        <div class="account-card">
          <ion-list>
            <ion-item detail='true' @click="UpdateNumber">
              <div slot="start" class="start">
                <ion-icon class="icon" :icon="$allIcons.personOutline"></ion-icon>
              </div>
              <div class="list-label">
                <span class="list-txt">Guhindura numero</span>
              </div>
            </ion-item>
            <ion-item detail='true' @click="UpdateEmail">
              <div slot="start" class="start">
                <ion-icon class="icon" :icon="$allIcons.mailOutline"></ion-icon>
              </div>
              <div class="list-label">
                <span class="list-txt">Guhindura Email</span>
              </div>
            </ion-item>
            <ion-item detail='true' @click="UpdatePassword">
              <div slot="start" class="start">
                <ion-icon class="icon" :icon="$allIcons.lockClosedOutline"></ion-icon>
              </div>
              <div class="list-label">
                <span class="list-txt">Guhindura ijambo kabanga</span>
              </div>
            </ion-item>
            <ion-item detail='true'>
              <div slot="start" class="start">
                <ion-icon class="icon" :icon="$allIcons.listOutline"></ion-icon>
              </div>
              <div class="list-label">
                <span class="list-txt">Ingene nagiye ndariha</span>
              </div>
            </ion-item>

            <!-- <ion-item>
          <div slot="start" class="start">
            <ion-icon class="logout" :icon="$allIcons.archiveOutline"></ion-icon>
          </div>
          <span class="sub-gray">history(cfr time watch)</span>
        </ion-item> -->


          </ion-list>
        </div>
        <div class="account-card" @click="confirmLogout">
          <div class="logout">
            <ion-icon :icon="$allIcons.logOut"></ion-icon>
            <span>Kwugara</span>
          </div>
        </div>
        <div class="account-card">
          Impinduro 1.0.0
        </div>
        <div class="account-card">
          <!-- <a href="https://hogi.rw" target="_blank">Fresh Studio. © 2024</a> -->
          <span>Fresh Studio. © 2024</span>
          <span class="sub-gray">Uburenganzira bwose buratwegukiye</span>
          <div class="dev">
            <span>Yakozwe na </span>
            <a href="https://egeratech.com" target="_blank">Egera Tech</a>
          </div>
        </div>
        <div class="whatsapp" @click="demanderAide()">
          <ion-icon :icon="$allIcons.logoWhatsapp"></ion-icon>
        </div>
        <OurNumber v-show="modal_number" @close="close">
        </OurNumber>
        <OurEmail v-show="modal_email" @close="close">
        </OurEmail>
        <OurPassword v-show="modal_password" @close="close">
        </OurPassword>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import OurNumber from '@/components/account/OurNumber.vue'
import OurEmail from '@/components/account/OurEmail.vue'
import OurPassword from '@/components/account/OurPassword.vue'

export default {
  data() {
    return {
      modal_number: false,
      modal_email: false,
      modal_password: false,
    };
  },
  components: {
    OurNumber,
    OurEmail,
    OurPassword
  },
  methods: {
    close() {
      this.modal_number = false
      this.modal_email = false
      this.modal_password = false
    },
    UpdateNumber() {
      this.modal_number = true
    },
    UpdateEmail() {
      this.modal_email = true
    },
    UpdatePassword() {
      this.modal_password = true
    },
    YourBillings() {
      this.$router.push('/billings');
    },
    mounted() {
    },
  }
}
</script>

<style>
.profile {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.profile .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 10px;
}

.item-list {
  display: flex;
  gap: 5px;
}

.item-list ion-icon {
  color: var(--white);
}

.profile ion-icon {
  background-color: var(--gray);
  color: var(--bg);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding: 10px;
}

.start {
  width: 30px;
  height: 30px;
  border-radius: 7px;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.start .icon {
  color: var(--premier);
}

.logout {
  color: var(--red);
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.dev {
  display: flex;
  gap: 5px;
}

.account-card {
  background-color: var(--bg1);
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 16px;
  padding: 10px;
  border-radius: 8px;
}

ion-list {
  --ion-background-color: transparent;
  margin: 0;
  padding: 0;
}

.whatsapp {
  font-size: 25px;
  color: var(--green);
  position: fixed;
  bottom: 55px;
  right: 30px;
}
</style>
