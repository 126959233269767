<template>
  <div class="toolbar-start">
    <ion-buttons slot="start">
      <span>Fresh Movie</span>
    </ion-buttons>
    <span class="header-title">Ishikiro</span>
    <div class="header-icon">
      <ion-icon :icon="$allIcons.searchOutline"></ion-icon>
    </div>
    <!-- <span></span> -->
  </div>
  <ion-content class="ion-padding">
    <!-- <div class="search-container">
      <ion-searchbar class="search-button"></ion-searchbar>
      <ion-icon :icon="$allIcons.filterOutline"></ion-icon>
    </div> -->
    <OurRecently />
    <OurTrending />
    <OurRecommended />
    <div class="foot-space"></div>
  </ion-content>
</template>

<script>
import OurRecently from '@/components/OurRecently.vue';
import OurTrending from '@/components/OurTrending.vue';
import OurRecommended from '@/components/OurRecommended.vue';

export default {
  data() {
    return {

    };
  },
  components: {
    OurRecently,
    OurTrending,
    OurRecommended,
  },
  methods: {
    // goLive() {
    //     this.$router.push('/tabs/live');
    // },
  },
  mounted() {
    // this.getEventNow();
  },
};
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
</style>